<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12 sm12>
                <v-card style="overflow: hidden" class="primary--border" outlined>
                    <v-card-title class="title">
                        Routine
                        <v-spacer></v-spacer>
                        <div>
                            <transition name="animate-css-transition"
                                        enter-active-class="animated fadeInRight"
                                        leave-active-class="animated fadeOutRight">
                                <v-btn v-if="subject && !filter" style="margin-right: 25px"
                                       :color="editDisable?'error':'warning'"
                                       dark
                                       icon outlined
                                       small
                                       @click="editDisable=!editDisable">
                                    <v-icon small>{{editDisable?'close':'edit'}}</v-icon>
                                </v-btn>
                            </transition>

                            <transition name="animate-css-transition"
                                        enter-active-class="animated fadeInRight"
                                        leave-active-class="animated fadeOutRight">
                                <add-button :permission="'subject-routine-create'" style="right: 5%"
                                            v-if="section && !filter"
                                            @action="form.dialog=true,getSubjects(),update=false,form.day=[],$refs.form ? $refs.form.reset():'' ">
                                    New Routine
                                </add-button>
                            </transition>
                            <!--                           <transition>-->
                            <!--                                <v-btn :color="filter ? 'warning' : 'primary'"-->
                            <!--                                       fab-->
                            <!--                                       dark-->
                            <!--                                       small-->
                            <!--                                       right-->
                            <!--                                       @click="filter = !filter"-->
                            <!--                                >-->
                            <!--                                    <v-icon>{{filter? 'filter_list' :-->
                            <!--                                        'view_headline'}}-->
                            <!--                                    </v-icon>-->
                            <!--                                </v-btn>-->
                            <!--                            </transition>-->

                            <!--                                <refresh-button v-show="form.items.data.length" @action="get()"></refresh-button>-->

                            <!--                            <transition name="animate-css-transition"-->
                            <!--                                        enter-active-class="animated fadeInRight"-->
                            <!--                                        leave-active-class="animated fadeOutRight"-->
                            <!--                                        v-if="filter && form.items.data.length">-->
                            <!--                                <v-btn fab @click="printRoutine" color="primary"-->
                            <!--                                       class="mx-2" dark small-->
                            <!--                                       style="position: absolute;right: 60px;">-->
                            <!--                                    <v-icon small color="white">print</v-icon>-->
                            <!--                                </v-btn>-->
                            <!--                            </transition>-->
                            <!--<v-btn :disabled="disabled" outlined small :color="color || 'success'" @click="$emit('action')">-->
                            <!--<v-icon left small>{{ icon || 'cloud_download' }}</v-icon>-->
                            <!---->
                            <!--</v-btn>-->

                            <v-btn @click="filter = !filter" outlined small color="warning">
                                <v-icon v-if="filter">filter_list</v-icon>
                                <v-icon v-else>view_headline</v-icon>
                                Switch View
                            </v-btn>
                            &nbsp;
                            <download-button v-if="filter && form.items.data.length" @action="printRoutine">Print
                                Routine
                            </download-button>
                        </div>
                    </v-card-title>
                    <v-card outlined>
                        <!--<v-container grid-list-md>-->
                        <v-card-title>
                        <v-layout row wrap>
                                <v-flex xs3 sm3>
                                    <v-select :items="grades" :loading="gradeLoading" :disabled="!grades.length"
                                              class="pa-0" label="Grade" v-model="grade" outlined dense/>
                                </v-flex>
                                <v-flex xs3 sm3>
                                    <v-select :items="sections" :disabled="!sections.length"
                                              class="pa-0" label="Section" outlined dense
                                              v-model="section"/>
                                </v-flex>
                                <v-flex xs3 sm3 v-if="!filter">
                                    <v-select :items="days" class="pa-0" label="Select Days" v-model="day"
                                              :disabled="!section" outlined dense/>
                                </v-flex>
                                <v-flex xs3 sm3 v-if="!filter">
                                    <v-select :items="subjects" class="pa-0" label="Select Subjects" v-model="subject"
                                              :disabled="!section"
                                              :loading="subjectLoading" outlined dense/>
                                </v-flex>
                                <v-flex xs12 v-if="subject">
                                    <strong style="color:#ff9800;font-size: 12px;">
                                        <v-icon color="#ff9800" style="font-size: 14px">warning</v-icon>
                                        {{'Start date and End date should be on 24 hrs time format.' }}
                                    </strong>
                                </v-flex>
                            </v-layout>
                        </v-card-title>
                        <!--</v-container>-->
                    </v-card>
                    <template v-if="!filter">
                        <v-data-table
                                :headers="subjectHeaders"
                                :items="subjectRoutines"
                                v-if="subject"
                                hide-default-footer
                                :search="search"
                                :options.sync="pagination"
                                :footer-props="footerProps"
                                :server-items-length="form.items.meta.total">
                            <template v-slot:item="{index, item}">
                                <tr>
                                    <td class="text-xs-left">{{item.routine_day.capitalizeFirstLetter()}}</td>
                                    <td class="text-xs-left"><input v-model="item.start_time"
                                                                    @keyup="startTimeChange(index, item.start_time)"
                                                                    type="text" v-mask="'##:##'" class="inputTime"
                                                                    :disabled="!editDisable"></td>
                                    <td class="text-xs-left">
                                        <input @keyup="endTimeChange(index, item.end_time)"
                                               v-model="item.end_time" type="text" v-mask="'##:##'"
                                               class="inputTime" :disabled="!editDisable"></td>
                                    <td class="text-xs-left"><input v-model="item.period"
                                                                    @keyup="periodChange(index, item.period)"
                                                                    type="text" v-mask="'##'"
                                                                    class="inputTime" :disabled="!editDisable"></td>
                                    <td class="text-xs-left">
                                        <v-autocomplete
                                                v-model="item.teacher_id"
                                                :items="teachersList"
                                                filled
                                                @change="teacherChange(index, item.teacher_id)"
                                                item-text="name"
                                                item-value="id"
                                                rounded
                                                :disabled="!editDisable"></v-autocomplete>
                                        <span class="teacherInfo">{{filterObjectFromArray(item.teacher_id, teachersList) ? filterObjectFromArray(item.teacher_id, teachersList).email:''}}
                                            <span v-if="filterObjectFromArray(item.teacher_id, teachersList) && filterObjectFromArray(item.teacher_id, teachersList).email && filterObjectFromArray(item.teacher_id, teachersList).primary_mobile">,
                                            </span> {{filterObjectFromArray(item.teacher_id, teachersList)? filterObjectFromArray(item.teacher_id, teachersList).primary_mobile :''}}</span>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                        <v-card-actions v-if="subject && subjectRoutines.length">
                            <v-spacer/>
                            <v-btn color="success" outlined @click="submitSubject"
                                   :disabled="!editDisable">Update
                            </v-btn>
                        </v-card-actions>
                        <v-data-table
                                :headers="headers"
                                :items="form.items.data"
                                v-if="!subject"
                                :search="search"
                                :footer-props="footerProps"
                                :options.sync="pagination"
                                :server-items-length="form.items.meta.total">
                            <template v-slot:item="{index, item}">
                                <tr>
                                    <td class="text-xs-left">{{ item.start_time }} - {{ item.end_time }}</td>
                                    <td class="text-xs-left">{{ item.routine_type | capitalize }}</td>
                                    <td class="text-xs-left">{{ item.routine_day | capitalize }}</td>
                                    <td class="text-xs-left">{{ item.subject ? (item.subject.name ?
                                        item.subject.name:''):'' }}
                                    </td>
                                    <td class="text-xs-left">{{ item.period }}</td>
                                    <td class="text-xs-left">{{ item.shift | capitalize }}</td>
                                    <td class="text-xs-left">{{ item.teacher ? (item.teacher.name ?
                                        item.teacher.name :''):'' }}
                                    </td>
                                    <td class="text-xs-right">
                                        <edit-button permission="subject-routine-update"
                                                     @agree="updateRoutine(item)"/>
                                        <delete-button permission="subject-routine-delete"
                                                       @agree="form.delete(item.id)"/>
                                    </td>
                                </tr>
                            </template>

                        </v-data-table>
                    </template>
                    <template v-else>
                        <!--Routine Table-->
                        <div>
                            <div class="v-table__overflow">
                                <table class="v-datatable v-table theme--light" style="width: 100%">
                                    <thead>
                                    <tr style="text-align: center">
                                        <th style="border: 1px solid rgba(0, 0, 0, 0.22)">Day/Period(time)</th>
                                        <th style="border: 1px solid rgba(0, 0, 0, 0.22)" v-for="(day, key) in baar"
                                            :key="key">{{day.capitalizeFirstLetter()}}
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody v-if="periodCount > 0 "
                                           style="text-align: center;border-bottom: 1px solid rgba(0,0,0,.12);">
                                    <tr v-for="(i, index) in periodCount" :key="index">
                                        <th style="border: 1px solid rgba(0, 0, 0, 0.22)">
                                            {{i}}<sup>{{getThForm(i)}}</sup>
                                            <span v-if="periodWiseData[i][0]">
                                                <p v-if="periodWiseData[i][0].period === i && periodWiseData[i][0].routine_day ==='sunday'">
                                                    {{ periodWiseData[i][0].start_time}} - {{ periodWiseData[i][0].end_time}}
                                                </p>
                                            </span>
                                        </th>
                                        <td v-for="(day, keyBar) in baar" :key="keyBar">
                                            <template v-for="(d,x) in periodWiseData[i]">
                                                <div v-if="d.routine_day === day" :key="x">
                                                <span class="cellHover" @click="updateRoutine(d)">
                                                    <span style="font-weight: bold">{{d.subject ? d.subject.name.capitalizeFirstLetter() : ''}}</span>
                                                    <p style="font-size: 10px">{{d.teacher ? d.teacher.name : ''}}</p>
                                                </span>
                                                </div>
                                            </template>
                                        </td>

                                    </tr>

                                    </tbody>
                                    <tbody v-else>
                                    <tr>
                                        <td colspan="8" class="text-center">No Data Available</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </template>
                </v-card>
            </v-flex>
            <v-dialog v-model="form.dialog" persistent max-width="500px">
                <v-card>
                    <v-card-title class="primary white--text">
                        <span class="title">Add/Update</span>
                        <v-spacer></v-spacer>
                        <span class="title">{{selectedGrades.text}} <sup>({{selectedSections.text}})</sup></span>
                    </v-card-title>
                    <v-card-text class="pb-1">
                        <v-form ref="form"
                                v-model="valid"
                                :lazy-validation="lazy"
                                @submit.prevent="save"
                                @keydown.native="form.errors.clear($event.target.name)" @keyup.enter="save">
                            <v-container grid-list-md>
                                <v-layout row wrap>
                                    <v-flex xs12 sm6>
                                        <v-select :items="subjects" :disabled="!subjects.length"
                                                  :loading="subjectLoading"
                                                  required class="pa-0 pl-2" label="Subject*"
                                                  v-model="form.subject_id"
                                                  :error-messages="form.errors.get('subject_id')"
                                                  name="subject_id" outlined dense
                                                  :rules="[v => !!v || 'Subject is required']"
                                        />
                                    </v-flex>
                                    <v-flex xs12 sm6>
                                        <v-text-field autocomplete="off" label="Period*" required class="pa-0 pl-2"
                                                      v-model="form.period" name="period"
                                                      :error-messages="form.errors.get('period')" type="number" outlined
                                                      dense
                                                      :rules="[v => !!v || 'Period is required']"
                                        />
                                    </v-flex>
                                    <v-flex xs12 sm6>
                                        <v-dialog ref="startDialog" v-model="startTimeDialog"
                                                  :return-value.sync="form.start_time" persistent
                                                  width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field v-model="form.start_time" label="Start Time" required
                                                              readonly
                                                              v-on="on" class="pa-0 pl-2"
                                                              :error-messages="form.errors.get('start_time')"
                                                              name="start_time" outlined dense
                                                              :rules="[v => !!v || 'Start Time is required']"
                                                />
                                            </template>
                                            <v-time-picker v-if="startTimeDialog" v-model="form.start_time">
                                                <v-spacer></v-spacer>
                                                <v-btn outlined color="primary" @click="startTimeDialog = false">Cancel
                                                </v-btn>
                                                <v-btn outlined color="primary"
                                                       @click="$refs.startDialog.save(form.start_time)">OK
                                                </v-btn>
                                            </v-time-picker>
                                        </v-dialog>
                                    </v-flex>
                                    <v-flex xs12 sm6>
                                        <v-dialog ref="endDialog" v-model="endTimeDialog"
                                                  :return-value.sync="form.end_time"
                                                  persistent width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field v-model="form.end_time" label="End Time" required readonly
                                                              v-on="on" class="pa-0 pl-2"
                                                              :error-messages="form.errors.get('end_time')"
                                                              name="end_time" outlined dense
                                                              :rules="[v => !!v || 'End Time is required']"
                                                />
                                            </template>
                                            <v-time-picker v-if="endTimeDialog" v-model="form.end_time">
                                                <v-spacer></v-spacer>
                                                <v-btn outlined color="primary" @click="endTimeDialog = false">Cancel
                                                </v-btn>
                                                <v-btn outlined color="primary"
                                                       @click="$refs.endDialog.save(form.end_time)">
                                                    OK
                                                </v-btn>
                                            </v-time-picker>
                                        </v-dialog>
                                    </v-flex>
                                    <v-flex xs12 sm6>
                                        <v-select :items="routineType" required class="pa-0 pl-2" label="Routine Type*"
                                                  v-model="form.routine_type"
                                                  :error-messages="form.errors.get('routine_type')" outlined dense
                                                  :rules="[v => !!v || 'Routine Type is required']"/>
                                    </v-flex>
                                    <v-flex xs12 sm6>
                                        <v-select :items="shifts" required class="pa-0 pl-2" label="Shift*"
                                                  v-model="form.shift" :error-messages="form.errors.get('shift')"
                                                  outlined dense :rules="[v => !!v || 'Shift is required']"/>
                                    </v-flex>

                                    <v-flex xs12>
                                        <v-select :multiple="!update" v-model="form.day"
                                                  :error-messages="form.errors.get('routine_day')"
                                                  :items="selectDaysExceptAll"
                                                  label="Select Days"
                                                  class="pt-0 pl-2" outlined dense
                                                  :rules="[v => !!v || 'Days is required']"

                                        >
                                            <v-list-item v-if="!update" slot="prepend-item" ripple @click="toggle">
                                                <v-list-item-action>
                                                    <v-icon :color="form.day.length > 0 ? 'indigo darken-4' : ''">{{
                                                        icon
                                                        }}
                                                    </v-icon>
                                                </v-list-item-action>
                                                <v-list-item-title>Select All</v-list-item-title>
                                            </v-list-item>
                                            <v-divider slot="prepend-item" class="mt-2"></v-divider>
                                            <v-divider slot="append-item" class="mb-2"></v-divider>
                                        </v-select>
                                        <!--<v-select :items="days" multiple required class="pa-0 pl-2" label="Day*"-->
                                        <!--v-model="form.routine_day" :error-messages="form.errors.get('shift')"/>-->
                                    </v-flex>

                                    <v-flex xs12>
                                        <v-autocomplete
                                                class="pl-2"
                                                v-model="form.teacher_id"
                                                :items="items"
                                                :loading="isLoading"
                                                :search-input.sync="search"
                                                hide-no-data
                                                persistent-hint
                                                :hint="getSelectedTeacher.primary_mobile || getSelectedTeacher.email"
                                                item-text="Name"
                                                item-value="id"
                                                label="Teacher"
                                                placeholder="Start typing to Search" outlined dense></v-autocomplete>
                                        <!--<v-select :items="teachers" required class="pa-0 pl-2" label="Teacher*"-->
                                        <!--v-model="form.teacher_id"-->
                                        <!--:error-messages="form.errors.get('teacher_id')"/>-->
                                    </v-flex>
                                </v-layout>
                                <small>*indicates required field</small>
                            </v-container>

                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="warning" text @click="form.dialog = false, form.reset(),$refs.form.reset()">
                            Close
                        </v-btn>
                        <v-btn color="success" text @click="validate">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </v-container>
</template>
<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'
    import {filterObjectFromArray, getThForm} from "../../../../library/helpers";
    import Mixins from '@/library/Mixins';

    export default {
        mixins: [Mixins],
        data: () => ({
            valid: true,
            lazy: false,
            form: new Form({
                start_time: '',
                end_time: '',
                routine_day: '',
                routine_time: '',
                routine_id: '',
                section_id: '',
                grade_id: '',
                subject_id: '',
                routine_type: '',
                teacher_id: '',
                period: '',
                shift: '',
                day: ['sunday'],
            }, '/api/routine'),
            search: '',
            searchKey: null,
            update: false,
            periodCount: 0,
            filter: true,
            periodWiseData: [],
            pagination: {
                itemsPerPage: 100
            },
            headers: [
                {text: 'Time', align: 'left', value: 'start_time', width: 125, sortable: false},
                {text: 'Type', align: 'left', value: 'routine_type', sortable: false},
                {text: 'Day', align: 'left', value: 'routine_day', sortable: false},
                {text: 'Subject', align: 'left', value: 'subject', sortable: false},
                {text: 'Period', align: 'center', value: 'period', sortable: false},
                {text: 'Shift', align: 'left', value: 'shift', sortable: false},
                {text: 'Teacher', align: 'left', value: 'teacher', sortable: false},
                {text: 'Action', align: 'center', sortable: false}
            ],
            subjectHeaders: [
                {text: 'Day', align: 'left', value: 'day', width: 125, sortable: false},
                {text: 'Start Time', align: 'left', value: 'start_time', sortable: false},
                {text: 'End Time', align: 'left', value: 'end_time', sortable: false},
                {text: 'Period', align: 'left', value: 'period', sortable: false},
                {text: 'Teacher', align: 'left', value: 'teacher', sortable: false},
            ],
            day: 'all',
            sections: [],
            editDisable: false,
            grades: [],
            gradeLoading: false,
            grade: '',
            section: '',
            subject: '',
            subjects: [],
            teachersList: [
                {id: 1, name: 'ram'},
                {id: 2, name: 'gopal'},
                {id: 3, name: 'hari'},
                {id: 4, name: 'shyam'}
            ],
            subjectLoading: false,
            teachers: [],
            subjectRoutines: [],
            days: [
                {text: 'All', value: 'all'},
                {text: 'Sunday', value: 'sunday'},
                {text: 'Monday', value: 'monday'},
                {text: 'Tuesday', value: 'tuesday'},
                {text: 'Wednesday', value: 'wednesday'},
                {text: 'Thursday', value: 'thursday'},
                {text: 'Friday', value: 'friday'},
                {text: 'Saturday', value: 'saturday'},
            ],
            baar: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],
            shifts: [
                {text: 'Morning', value: 'morning'},
                {text: 'Day', value: 'day'},
                {text: 'Evening', value: 'evening'},
            ],
            routineType: [
                {text: 'Theory', value: 'theory'},
                {text: 'Practical', value: 'practical'},
                {text: 'Leisure', value: 'leisure'},
            ],
            startTimeDialog: false,
            endTimeDialog: false,
            isLoading: false,
            entries: [],
            printUrl: ''
        }),

        computed: {
            ...mapState(['batch']),
            selectAllDays() {
                return this.form.day.length === this.days.length
            },
            selectFewDays() {
                return this.form.day.length > 0 && !this.selectAllDays
            },
            icon() {
                if (this.selectAllDays) return 'check_box';
                if (this.selectFewDays) return 'indeterminate_check_box';
                return 'add_box'
            },
            selectDaysExceptAll() {
                return this.days.filter(function (day) {
                    if (day.value !== 'all') return day;
                });
            },
            selectedGrades() {
                let $this = this;
                let selGr = this.grades.filter(function (grade) {
                    if ($this.grade === grade.value) return grade;
                });
                if (selGr.length) return selGr[0];
                return {};
            },

            selectedSections() {
                let $this = this;
                let selSec = this.sections.filter(function (sec) {
                    if ($this.section === sec.value) return sec;
                });
                if (selSec.length) return selSec[0];
                return {};
            },
            items() {
                return this.entries.map(entry => {
                    return Object.assign({}, entry, {Name: entry.name})
                })
            },
            getSelectedTeacher() {
                let teacher = {};
                let $this = this;
                this.entries.map(function (data) {
                    if ($this.form.teacher_id === data.id) {
                        teacher = data;
                    } else if ($this.update) {
                        teacher = data;
                    }
                });
                return teacher;
            }
        },

        mounted() {
            this.getGrades();
        },

        watch: {
            'pagination': function () {
                this.get();
            },
            'batch': function (value) {
                this.getGrades();
                this.get();
            },
            'grade': function () {
                this.sections = [];
                this.section = '';
                this.subjects = [];
                this.editDisable = true;
                this.form.items.data = [];
                this.periodWiseData = [];
                this.periodCount = 0;
                this.getSections();

            },
            'section': function () {
                this.editDisable = true;
                this.subject = '';
                this.periodWiseData = [];
                this.periodCount = 0;
                this.get();
                this.getSubjects();

            },
            'day': function () {
                this.get();
            },
            'subject': function () {
                this.getSubjectRoutines();
                this.getTeachers();
                this.editDisable = false;
            },
            search(val) {
                if (!val) return;
                if (this.isLoading) return;

                this.isLoading = true;
                this.$rest.get('api/user-search/slim?type=teacher&search=' + val)
                    .then(({data}) => {
                        this.entries = data.data
                    }).catch(err => {
                }).finally(() => (this.isLoading = false))
            }
        },

        methods: {
            filterObjectFromArray, getThForm,

            validate() {
                if (this.$refs.form.validate()) {
                    this.save()
                }
            },

            get(params) {
                if (this.section && this.day) {
                    let extraParams = 'sectionId=' + this.section + '&day=' + this.day;
                    // let query = [null, undefined].includes(params) ? this.queryString() : params;
                    this.form.get(null, this.queryString(extraParams)).then(({data}) => {
                        let periodCollection = [];
                        this.periodWiseData = [];
                        data.data.map(res => {
                            periodCollection.push(parseInt(res.period))
                        });
                        if (periodCollection.length > 0) {
                            this.periodCount = periodCollection.reduce(function (a, b) {
                                return Math.max(a, b);
                            });
                            let i = 1;
                            for (i = 1; i <= this.periodCount; i++) {
                                this.periodWiseData[i] = data.data.filter(res => {
                                    if (res.period === i) return res;
                                });
                            }
                        }
                        this.printUrl = data.print_url;
                    })
                }
            },

            save() {
                this.form.section_id = this.section;
                this.form.grade_id = this.grade;
                if (this.form.day.length > 0 && typeof this.form.day === 'object' && this.form.day.indexOf('all') > -1) {
                    this.form.day.splice(this.form.day.indexOf('all'), 1);
                } else {
                    this.form.routine_day = this.form.day;
                }
                if (!this.update) {
                    this.form.routine_day = this.form.day.join(',');
                }
                this.form.store().then(res => {
                    this.get();
                })
            },
            getGrades() {
                this.sections = [];
                this.subjects = [];
                this.editDisable = true;
                this.form.items.data = [];

                this.gradeLoading = true;
                this.$rest.get('/api/grades?rowsPerPage=25&orderBy=rank&descending=false').then(({data}) => {
                    this.grades = data.data.map(item => {
                        return {value: item.id, text: item.name}
                    });
                }).finally(() => {
                    this.gradeLoading = false;
                })
            },
            getSubjects() {
                this.subjectLoading = true;
                this.$rest.get('/api/subject?rowsPerPage=25&grade=' + this.grade + '&batch=' + this.batch.id).then(({data}) => {
                    this.subjects = [];
                    this.subjects.push({value: '', text: 'All Subjects'});
                    data.data.map(item => {
                        this.subjects.push({value: item.id, text: item.name});
                    });
                }).finally(() => {
                    this.subjectLoading = false;
                })
            },
            getTeachers() {
                this.$rest.get('/api/teacher').then(res => {
                    this.teachersList = res.data.data;
                    // this.teachers = data.data.map(item => {
                    //     return {value: item.id, text: item.name}
                    // });
                })
            },
            getSections() {
                this.sections = [];
                this.$rest.get('/api/section?batch=' + this.batch.id + '&grade=' + this.grade).then(({data}) => {
                    this.sections = data.data.map(item => {
                        return {value: item.id, text: item.name}
                    });
                })
            },
            toggle() {
                this.$nextTick(() => {
                    if (this.selectAllDays) {
                        this.form.day = []
                    } else {
                        this.form.day = this.days.map(grade => {
                            return grade.value;
                        })
                    }
                })
            },
            updateRoutine(item) {
                this.form.edit(item);
                this.form.day = item.routine_day;
                if (Object.keys(item.teacher).length > 0) {
                    this.entries = [{
                        name: item.teacher.name,
                        Name: item.teacher.name,
                        email: item.teacher.email,
                        id: item.teacher.id
                    }];
                }
                this.getSubjects();
                this.update = true;
            },
            getSubjectRoutines() {
                if (this.subject) {
                    this.subjectRoutines = [];
                    this.$rest.get('/api/routine-subject-wise?sectionId=' + this.section + '&subjectId=' + this.subject).then(res => {
                        this.subjectRoutines = res.data.data;
                    })
                }
            },
            submitSubject() {
                if (this.subject) {
                    this.$rest.post('/api/routine-subject-wise', {routines: this.subjectRoutines}).then(res => {
                        this.$events.fire('notification', {message: res.data.message, status: 'success'});
                        this.editDisable = false;
                    }).catch(e => {
                        this.$events.fire('notification', {message: res.message, status: 'error'});
                    })
                }
            },
            teacherSearch(e) {
                if (this.searchKey !== null)
                    this.$rest.get('api/user-search/slim?type=teacher&search=' + e.target.value)
                        .then(({data}) => {
                            this.teachersList[this.searchKey] = [];
                            data.data.map(res => {
                                this.teachersList[this.searchKey].push({name: res.name, id: res.id});
                            });

                        }).catch(err => {
                    }).finally(() => (this.isLoading = false))
            },
            startTimeChange(index, value) {
                if (index == 0) {
                    this.subjectRoutines.map(res => {
                        res.start_time = value;
                    });
                }
            },
            endTimeChange(index, value) {
                if (index == 0) {
                    this.subjectRoutines.map(res => {
                        res.end_time = value;
                    });
                }
            },
            teacherChange(index, value) {
                if (index == 0) {
                    this.subjectRoutines.map(res => {
                        res.teacher_id = value;
                    });
                }
            },
            periodChange(index, value) {
                if (index == 0) {
                    this.subjectRoutines.map(res => {
                        res.period = value;
                    });
                }
            },
            printRoutine() {
                let url = this.printUrl + '?gradeId=' + this.grade + '&sectionId=' + this.section;
                window.open(url)
                // console.log(url)
            }
        }
    }
</script>
<style lang="scss" scoped>
    .inputTime {
        border: solid 1px #777;
        width: 65px;
        text-align: center;
        height: 32px;
    }

    .teacherInfo {
        position: absolute;
        margin-top: -21px;
        font-size: 12px;
        color: #777;
    }

    .inputTime:disabled {
        cursor: not-allowed;
        background: #99999921;
    }

    .theme--light.v-table tbody td {
        border: 1px solid rgba(0, 0, 0, .22);
    }

    .cellHover {
        cursor: pointer;
    }

    table td div p {
        padding-bottom: 0px;
        margin-bottom: 7px;
    }

    table td div {
        margin-top: 5px;
    }

    table td div:nth-child(2n+1) {
        border-bottom: 1px solid #ccc;
    }

    table td div:last-child {
        border-bottom: none;
    }
</style>